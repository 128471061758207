import {Layout, theme} from 'antd';
import SidebarContent from './SidebarContent';

const {Sider} = Layout;

const Sidebar = () => {
  const {
    token: {colorBgContainer},
  } = theme.useToken();
  return (
    <Sider
      width={250}
      trigger={null}
      breakpoint="xl"
      collapsedWidth="0"
      collapsed={false}
      className="h-[100vh] hidden xl:block"
      style={{
        top: 80,
        background: colorBgContainer,
      }}
    >
      <SidebarContent />
    </Sider>
  );
};

export default Sidebar;
