import { Button, Col, Row, Space, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/table/Index';
import { useCollection } from '../../helpers/firestore';
import { tableAddbuttons } from '../../helpers/theme_helper';
import { WazMelbetModel } from '../../types';
import AccountAdd from './DepoAccAdd';

import { UserContext } from '../../context/app.contexts';
import { Routes } from '../../routes/route_helper';

import MelbetIcon from '../../assets/images/icons/melbet.webp';
import XbetIcon from '../../assets/images/icons/1xbet.svg';
const Dashboard = () => {
 
  const { user } = useContext(UserContext);
  // const [wazUser, wazUserLoading] = useDoc(`wazs/${user.uid}`);
  const navigate = useNavigate();
  const [melbetIDs, melbetIDsLoading] = useCollection(
    `wazs/${user.uid}/depositAccounts/`,
    'createdAt',
  );

  const [firstAcc,setFirstAcc] = useState('1xbet')

  useEffect(() => {
    if (!melbetIDsLoading) {

      if (melbetIDs.lenght == 0) {

        toggleModal()
      }

    }
  }, [melbetIDsLoading]);

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const onClickDetail = (uid: string, type:string,accountID: string) => {

    navigate(Routes.compilePath(Routes.deposits, { id: uid, type:type, accountID: accountID }));

  };

  const onClickWithdraw = (uid: string, type:string,accountID: string) => {

    navigate(Routes.compilePath(Routes.withdraws, { id: uid,type:type, accountID: accountID }));

  };

  const columns: ColumnsType<WazMelbetModel> = [
    {
      key: 'type',
      title: 'Bet',
      dataIndex: 'type',
      width: '20%',
      render: (_, record) => (
        <>
        {record.type == '1xbet' && (
          <img alt="logo" src={XbetIcon} height={15} width="auto" style={{ margin: 2 }} />
        )
        }
              {record.type == 'melbet' && (
          <img alt="logo" src={MelbetIcon} height={22} width="auto" style={{ margin: 2 }} />
        )
        }
        </>

      )
    },
    {
      key: 'accountID',
      title: 'Account Number id',
      dataIndex: 'accountID',
      width: '20%',
    },

  
    {
      key: 'action',
      title: '',

      render: (_, record) => (
        <Row  >
          <Col style={{paddingLeft:10,paddingTop:10}}>
          <Button
            size="middle"
            type="primary"
            className="bg-success"
            onClick={() => {
              onClickDetail(record.uid, record.type,record.accountID);
            }}
          >Цэнэглэх</Button>
          </Col>
          <Col style={{paddingLeft:10,paddingTop:10}}>
          <Button
            size="middle"
            type="primary"
            className="bg-success"
            onClick={() => {
              onClickWithdraw(record.uid,record.type, record.accountID);
            }}
          >Таталт хийх</Button>
          </Col>
          </Row>
      ),
    },
  ];

  return (
    <div>

      <Spin spinning={melbetIDsLoading}>

        <DataTable
          tableName=""
          columns={columns}
          data={melbetIDs}
          loading={melbetIDsLoading}
          headerWidgers={[
            <>
            1xbet болон Melbet аккуантаа холбох {'--->'}
            </>,
            ...tableAddbuttons('Account Number id холбох', toggleModal)
          ]}
        />
        <AccountAdd uid={user.uid} open={open} toggle={toggleModal} />

      </Spin>
{/* 
      { melbetIDs && melbetIDs.length >0 && melbetIDs[0].type =='melbet' && (
        <>
             <MelbetMedias/>
             <XbetMedias/>
        </>
      )}
      { melbetIDs && melbetIDs.length >0 && melbetIDs[0].type =='1xbet' && (
        <>
          <MelbetMedias/>
             <XbetMedias/>
        </>
      )} */}
 
        </div>);
};

export default Dashboard;
