import {Layout} from 'antd';
import React, {useContext} from 'react';
import {Outlet} from 'react-router-dom';
import * as _ from 'lodash';

import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import {LoadingContext, UserContext} from '../context/app.contexts';
import {isAllowedRole} from '../constants/app_data';

const {Content} = Layout;

const MainLayout: React.FC = () => {
  const loadingPage = useContext(LoadingContext);
  const {loading, userRole} = useContext(UserContext);

  return loadingPage || loading  ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Layout>
      <Header />
      <Layout className="ml-0 xl:ml-[250px] mt-[80px]">
        <Sidebar />
        <Content
          className="m-[24px]"
          style={{
            height: 'calc(100vh - 128px)',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
