import {useState, useEffect} from 'react';
import {collectionData, docData} from 'rxfire/firestore';
import {firestore} from '../firebase';

export function useCollection(
  path: string,
  orderBy?: string,
  where?: any,
  limit?: number,
  descending = true,
  defaultValue = [],
) {
  const [state, setState] = useState<any>(defaultValue as any);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    let ref: any = firestore.collection(path);
    if (where) ref = ref.where(where.field, where.op, where.value);
    if (orderBy) ref = ref.orderBy(orderBy, descending ? 'desc' : 'asc');
    if (limit) ref = ref.limit(limit);

    let colsubscription = collectionData(ref, {idField: 'uid'}).subscribe(
      (data) => {
        setState(data);
        setLoading(false);
      },
    );
    return () => {
      colsubscription.unsubscribe();
    };
  }, [path]);
  return [state, loading];
}
export function useCollectionWithMulti(
  path: string,
  orderBy?: string,
  where?: any,
  where2?:any,
  limit?: number,
  descending = true,
  defaultValue = [],
) {
  const [state, setState] = useState<any>(defaultValue as any);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    let ref: any = firestore.collection(path);
    if (where) ref = ref.where(where.field, where.op, where.value);
    if (where2) ref = ref.where(where2.field, where2.op, where2.value);
    if (orderBy) ref = ref.orderBy(orderBy, descending ? 'desc' : 'asc');
    if (limit) ref = ref.limit(limit);

    let colsubscription = collectionData(ref, {idField: 'uid'}).subscribe(
      (data) => {
        setState(data);
        setLoading(false);
      },
    );
    return () => {
      colsubscription.unsubscribe();
    };
  }, [path]);
  return [state, loading];
}


export function useDoc(path: string, defaultValue = {}) {
  const [state, setState] = useState<any>(defaultValue);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const docRef: any = firestore.doc(path);
    const subscription = docData(docRef, {idField: 'uid'}).subscribe((data) => {
      setState(data ?? {});
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [path]);

  return [state, loading];
}

export const saveData = (
  colPath: string,
  docId: string | undefined,
  data = {},
  create = false,
) => {
  var dataValue = data;
  if (create) dataValue = {...dataValue, createdAt: new Date()};
  else dataValue = {...dataValue, updatedAt: new Date()};

  return !docId
    ? firestore.collection(`${colPath}`).add(dataValue)
    : firestore.doc(`${colPath}/${docId}`).set(dataValue, {merge: true});
};

export const updateData = (docPath: string, data = {}) => {
  data = JSON.parse(JSON.stringify(data));
  return firestore.doc(`${docPath}`).update({...data, updatedAt: new Date()});
};

export const removeData = (docPath: string) =>
  firestore.doc(`${docPath}`).delete();

export const getUidFirebase = async () => {
  const result = await firestore.collection('uid-generator').add({});
  return result.id;
};
