import {Routes, Route} from 'react-router-dom';

import AuthMiddleware from './middleware';
import {publicRoutes} from './routes';
import Layout from '../layouts/Main.layout';
import PublicLayout from '../layouts/Public.layout';
import {RouteModel} from '../types';
import {useContext} from 'react';
import {RouteContext} from '../context/app.contexts';

const Router = () => {
  const {authProtectedRoutes} = useContext(RouteContext);
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        {publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware isAuthProtected={false}>
                <route.component />
              </AuthMiddleware>
            }
          />
        ))}
      </Route>
      <Route element={<Layout />}>
        {(authProtectedRoutes as RouteModel[]).map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware isAuthProtected={true}>
                <route.component />
              </AuthMiddleware>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
