import { useEffect } from 'react';

const Android = () => {

  useEffect(() => {
    window.location.href = 'https://probet.mn/install.apk';
  }, []);

  return null;
};

export default Android;