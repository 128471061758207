


export type ApiResponse = {
  status: boolean;
  message: string;
  data: any | undefined;
  code: string;
};


export enum OtpType {
  APP_LOGIN = 'app',
}

