import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Layout, theme, Drawer} from 'antd';
import React, {useContext, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import AccountDropDown from './AccountDropdown';
import SidebarContent from './SidebarContent';
import LogoIcon from '../../assets/images/logo-icon.png';
const {Header: HeaderComponent} = Layout;

const Header = () => {
  const {user} = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: {colorBgContainer},
  } = theme.useToken();

  return (
    <React.Fragment>
      <HeaderComponent
        style={{
          zIndex: 2,
          paddingInline: '24px',
          background: colorBgContainer,
        }}
        className="fixed top-0 w-full flex items-center"
      >
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'trigger block xl:hidden',
            onClick: () => setCollapsed(!collapsed),
          },
        )}
        {/* <img
          className="logo-img hidden sm:block w-auto h-8 sm:h-10 ml-4 xl:ml-0"
          src={LogoIcon}
          alt="logo"
        /> */}
        <div className="flex items-center w-full">
          <div className="sm:mr-4"></div>
          <div className="text-2xl hidden sm:block">ProBet</div>
        </div>
        <AccountDropDown email={user?.phoneNumber} />
      </HeaderComponent>
      <Drawer
        width={250}
        title={'ProBet'}
        placement="left"
        open={collapsed}
        onClose={() => setCollapsed(false)}
      >
        <SidebarContent />
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
